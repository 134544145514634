import { supabase } from '../../../lib/supabase';
import { logger } from '../../../utils/debug';
import { handleApiError } from '../../../utils/error';
import type { News, NewsCategory, NewsStatus, UpdateNewsData } from '../types';
import { isValidTransition } from '../utils/statusTransitions';

interface CreateNewsData {
  title: string;
  content: string;
  category: NewsCategory;
  main_image: string;
  additional_images?: string[];
  author_id: string;
  published_date?: string;
  status: NewsStatus;
  original_article_url?: string;
  excerpt?: string;
}

export const newsApi = {
  async getAll(): Promise<News[]> {
    try {
      const { data, error } = await supabase
        .from('news')
        .select(`
          *,
          profiles:author_id (
            first_name,
            last_name
          )
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;

      return data.map(item => ({
        ...item,
        mainImage: item.main_image,
        additionalImages: item.additional_images,
        author_name: item.profiles ? 
          `${item.profiles.first_name || ''} ${item.profiles.last_name || ''}`.trim() || 'Anonyme'
          : 'Anonyme'
      }));
    } catch (error) {
      logger.error('Get all news error:', error);
      throw error;
    }
  },

  async getPublished(): Promise<News[]> {
    try {
      const { data, error } = await supabase
        .from('news')
        .select(`
          *,
          profiles:author_id (
            first_name,
            last_name
          )
        `)
        .eq('status', 'published')
        .order('published_date', { ascending: false });

      if (error) throw error;

      return data.map(item => ({
        ...item,
        mainImage: item.main_image,
        additionalImages: item.additional_images,
        author_name: item.profiles ? 
          `${item.profiles.first_name || ''} ${item.profiles.last_name || ''}`.trim() || 'Anonyme'
          : 'Anonyme'
      }));
    } catch (error) {
      logger.error('Get published news error:', error);
      throw error;
    }
  },

  async createNews(data: CreateNewsData): Promise<News> {
    try {
      logger.info('Creating news:', data);

      const currentDate = new Date().toISOString();
      const { data: newsData, error } = await supabase
        .from('news')
        .insert([{
          title: data.title,
          content: data.content,
          category: data.category,
          main_image: data.main_image,
          additional_images: data.additional_images || [],
          author_id: data.author_id,
          status: data.status,
          published_date: data.published_date || (data.status === 'published' ? currentDate : null),
          original_article_url: data.original_article_url
        }])
        .select(`
          *,
          profiles:author_id (
            first_name,
            last_name
          )
        `)
        .single();

      if (error) throw error;
      if (!newsData) throw new Error('Failed to create news article');

      return {
        ...newsData,
        mainImage: newsData.main_image,
        additionalImages: newsData.additional_images,
        author_name: newsData.profiles ? 
          `${newsData.profiles.first_name || ''} ${newsData.profiles.last_name || ''}`.trim() || 'Anonyme'
          : 'Anonyme'
      };
    } catch (error) {
      logger.error('Create news error:', error);
      throw error;
    }
  },

  async update(id: string, data: UpdateNewsData): Promise<News> {
    try {
      logger.info('Updating news:', { id, data });

      // Vérifier d'abord le statut actuel et le rôle de l'utilisateur
      const { data: currentNews, error: fetchError } = await supabase
        .from('news')
        .select('status')
        .eq('id', id)
        .single();

      if (fetchError) throw fetchError;
      if (!currentNews) throw new Error('Article non trouvé');

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Utilisateur non authentifié');

      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('role') 
        .eq('id', user.id)
        .single();

      if (profileError) throw profileError;
      if (!profile) throw new Error('Profil utilisateur non trouvé');

      const isAdmin = profile?.role === 'admin';

      // Vérifier si la transition est valide
      const canTransition = isValidTransition(currentNews.status, data.status, isAdmin);
      if (!canTransition) {
        throw new Error('STATUS_TRANSITION_ERROR');
      }
      
      // Mise à jour de la date de publication si l'article est publié
      const updates = {
        ...data,
        published_date: data.status === 'published' ? (data.published_date || new Date().toISOString()) : null,
        updated_at: new Date().toISOString()
      };

      const { data: newsData, error } = await supabase
        .from('news')
        .update(updates)
        .eq('id', id)
        .select(`
          *,
          profiles:author_id (
            first_name,
            last_name
          )
        `)
        .single();

      if (error) throw error;
      if (!newsData) throw new Error('Failed to update news article');

      return {
        ...newsData,
        mainImage: newsData.main_image,
        additionalImages: newsData.additional_images,
        author_name: newsData.profiles ? 
          `${newsData.profiles.first_name || ''} ${newsData.profiles.last_name || ''}`.trim() || 'Anonyme'
          : 'Anonyme'
      };
    } catch (error) {
      logger.error('Update news error:', error);
      throw error;
    }
  },

  async updateStatus(id: string, status: NewsStatus): Promise<void> {
    try {
      logger.info('Updating news status:', { id, status });
      // Vérifier d'abord le statut actuel
      const { data: currentNews, error: fetchError } = await supabase
        .from('news')
        .select('status')
        .eq('id', id)
        .single();
      if (fetchError) throw fetchError;
      if (!currentNews) throw new Error('Article non trouvé');
      
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Utilisateur non authentifié');
      
      // Récupérer le profil de l'utilisateur
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('role')
        .eq('id', user.id)
        .single();
      if (profileError) throw profileError;
      if (!profile) throw new Error('Profil utilisateur non trouvé');
      const isAdmin = profile?.role === 'admin';

      // Vérifier si la transition est valide
      if (!isValidTransition(currentNews.status, status, isAdmin)) {
        throw new Error('STATUS_TRANSITION_ERROR');
      }

      // Mise à jour du statut
      const updates = {
        status,
        published_date: status === 'published' && currentNews.status !== 'published'
          ? new Date().toISOString()
          : undefined
      };

      const { error: updateError } = await supabase
        .from('news')
        .update(updates)
        .eq('id', id);

      if (updateError) throw updateError;
    } catch (error) {
      logger.error('Update news status error:', error);
      throw error;
    }
  },

  async delete(id: string): Promise<void> {
    try {
      // Récupérer d'abord les informations de l'article pour avoir les URLs des images
      const { data: news, error: fetchError } = await supabase
        .from('news')
        .select('main_image, additional_images')
        .eq('id', id)
        .single();

      if (fetchError) throw fetchError;
      if (!news) throw new Error('Article non trouvé');

      // Supprimer l'image principale
      if (news.main_image) {
        const mainImageName = news.main_image.split('/').pop();
        if (mainImageName) {
          const { error: mainImageError } = await supabase.storage
            .from('news_images')
            .remove([mainImageName]);
          if (mainImageError) logger.error('Error deleting main image:', mainImageError);
        }
      }

      // Supprimer les images additionnelles
      if (news.additional_images && news.additional_images.length > 0) {
        const additionalImageNames = news.additional_images
          .map(url => url.split('/').pop())
          .filter(Boolean) as string[];

        if (additionalImageNames.length > 0) {
          const { error: additionalImagesError } = await supabase.storage
            .from('news_images')
            .remove(additionalImageNames);
          if (additionalImagesError) logger.error('Error deleting additional images:', additionalImagesError);
        }
      }

      // Supprimer l'article
      const { error } = await supabase
        .from('news')
        .delete()
        .eq('id', id);

      if (error) throw error;
    } catch (error) {
      logger.error('Delete news error:', error);
      throw error;
    }
  }
};