import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { useNewsEdit } from '../../features/news/hooks/useNewsEdit';
import { useNewsSubmission } from '../../features/news/hooks/useNewsSubmission';
import { NewsForm } from '../../features/news/components/NewsForm';
import { LoadingSpinner } from '../../components/ui/LoadingSpinner';

export default function NewsEdit() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const isAdmin = user?.role === 'admin';
  const { news, isLoading, error } = useNewsEdit(id || '');
  const { submitNews, isSubmitting, error: submitError } = useNewsSubmission();
  
  // Redirection si non admin
  if (!isLoading && !isAdmin) {
    navigate('/', { replace: true });
    return null;
  }

  if (isLoading) {
    return <LoadingSpinner fullScreen />;
  }

  if (error || !news) {
    return (
      <div className="min-h-screen bg-white dark:bg-retro-purple/95 pt-36 px-4">
        <div className="max-w-4xl mx-auto">
          <div className="bg-red-100 dark:bg-red-900/20 text-red-700 dark:text-red-400 p-4 rounded-lg">
            {error || 'Article non trouvé'}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-[73vh] bg-white dark:bg-retro-purple/95">
      <section className="pt-36 pb-20 px-4 relative overflow-hidden bg-gradient-to-b from-white dark:from-retro-purple to-retro-purple/5 dark:to-retro-purple/20">
        <div className="max-w-4xl mx-auto relative z-10">
          <Link 
            to="/dashboard"
            className="inline-flex items-center gap-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors mb-8"
          >
            <ArrowLeft className="w-4 h-4" />
            Retour au dashboard
          </Link>
          <div className="bg-white dark:bg-retro-purple/50 rounded-lg p-8 shadow-xl">
            <h2 className="text-3xl md:text-4xl font-display uppercase mb-6 text-retro-purple dark:text-white retro-shadow">
              Modifier l'article
            </h2>
  
            <NewsForm
              mode="edit"
              initialData={news}
              onSubmit={submitNews}
              isSubmitting={isSubmitting}
              error={submitError || error}
              isAdmin={isAdmin}
            />
          </div>
        </div>
      </section>
    </div>
  );
}