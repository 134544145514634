import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useRequireDashboard } from '../features/dashboard/hooks/useRequireDashboard';
import DashboardPage from '../pages/dashboard/DashboardPage';
import NewsUpdate from '../pages/news/NewsUpdate';
import { DashboardLayout } from '../features/dashboard/components/DashboardLayout';
import { LoadingSpinner } from '../components/ui/LoadingSpinner';

function DashboardLayoutWrapper() {
  const { isAdmin, isLoading } = useRequireDashboard();

  if (isLoading) {
    return <LoadingSpinner fullScreen />;
  }

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
}

export const dashboardRoutes = {
  path: '/dashboard/*',
  element: <DashboardLayoutWrapper />,
  children: [
    {
      path: '',
      element: <DashboardPage />
    },
    {
      path: 'news/edit/:id',
      element: <NewsUpdate />
    }
  ]
};