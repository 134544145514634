import { z } from 'zod';
import { NEWS_CATEGORIES } from './types';

export const newsSchema = z.object({
  title: z.string()
    .min(3, 'Le titre doit contenir au moins 3 caractères')
    .max(140, 'Le titre ne doit pas dépasser 140 caractères'),
  content: z.string()
    .min(100, 'Le contenu doit contenir au moins 100 caractères')
    .max(10000, 'Le contenu ne doit pas dépasser 10000 caractères'),
  category: z.enum(NEWS_CATEGORIES, {
    errorMap: () => ({ message: 'Veuillez sélectionner une catégorie' })
  }),
  main_image: z.string().min(1, "L'image principale est requise"),
  excerpt: z.string().optional(),
  additional_images: z.array(z.string()).optional(),
  published_date: z.string().nullable().optional(),
  original_article_url: z.string().optional()
    .nullable()
    .refine(
      (url) => {
        if (!url) return true;
        try {
          new URL(url);
          return true;
        } catch {
          return false;
        }
      },
      { message: 'URL invalide' }
    ),
  status: z.enum(['draft', 'submitted', 'published', 'archived']).default('draft')
});

export type NewsFormData = z.infer<typeof newsSchema>;

export function validateNews(data: unknown) {
  return newsSchema.safeParse(data);
}