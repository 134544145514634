import type { NewsStatus } from '../types';

interface StatusTransition {
  from: NewsStatus;
  to: NewsStatus[];
}

// Transitions autorisées pour les auteurs
const authorTransitions: StatusTransition[] = [
  { from: 'draft', to: ['submitted'] },
  { from: 'submitted', to: ['draft'] }
];

// Transitions autorisées pour les admins
const adminTransitions: StatusTransition[] = [
  { from: 'draft', to: ['submitted', 'published', 'archived'] },
  { from: 'submitted', to: ['draft', 'published', 'archived'] },
  { from: 'published', to: ['draft', 'submitted', 'archived'] },
  { from: 'archived', to: ['published'] }
];

/**
 * Vérifie si une transition de statut est valide
 */
export function isValidTransition(
  currentStatus: NewsStatus,
  newStatus: NewsStatus,
  isAdmin: boolean
): boolean {
  const transitions = isAdmin ? adminTransitions : authorTransitions;
  const allowedTransitions = transitions.find(t => t.from === currentStatus);
  return allowedTransitions?.to.includes(newStatus) || false;
}

/**
 * Récupère les transitions disponibles pour un statut
 */
export function getAvailableTransitions(
  currentStatus: NewsStatus,
  isAdmin: boolean
): NewsStatus[] {
  const transitions = isAdmin ? adminTransitions : authorTransitions;
  return transitions.find(t => t.from === currentStatus)?.to || [];
}