import { supabase } from './supabase';
import { logger } from '../utils/debug';

export const STORAGE_BUCKET = 'news_images';

interface UploadResult {
  url: string;
  path: string;
}

/**
 * Upload an image to Supabase Storage with validation
 */
export async function uploadImage(file: File): Promise<UploadResult> {
  try {
    // Validation du type de fichier
    const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
    if (!validTypes.includes(file.type)) {
      throw new Error('Format d\'image non supporté. Utilisez JPG, PNG ou WEBP.');
    }

    // Validation de la taille
    const MAX_SIZE = 5 * 1024 * 1024; // 5MB
    if (file.size > MAX_SIZE) {
      throw new Error('L\'image ne doit pas dépasser 5MB');
    }

    // Génération d'un nom de fichier unique
    const fileName = `${Date.now()}-${file.name.replace(/[^a-zA-Z0-9.]/g, '_')}`;
    
    logger.info('Uploading image:', { fileName, type: file.type, size: file.size });

    const { data, error } = await supabase.storage
      .from(STORAGE_BUCKET)
      .upload(fileName, file, {
        cacheControl: '3600',
        upsert: false
      });

    if (error) {
      logger.error('Storage upload error:', error);
      throw error;
    }

    if (!data?.path) {
      throw new Error('Missing file path after upload');
    }

    const { data: { publicUrl } } = supabase.storage
      .from(STORAGE_BUCKET)
      .getPublicUrl(data.path);

    logger.info('Upload successful:', { path: data.path, publicUrl });

    return {
      url: publicUrl,
      path: data.path
    };
  } catch (error) {
    logger.error('Image upload error:', error);
    throw error;
  }
}