import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { newsApi } from '../api/newsApi';
import type { CreateNewsData, UpdateNewsData, NewsStatus } from '../types';
import { validateNews } from '../validation';
import { logger } from '../../../utils/debug';
import { useAuth } from '../../../context/AuthContext';

export function useNewsSubmission() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string>();
  const [showSuccess, setShowSuccess] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const isAdmin = user?.role === 'admin';

  const submitNews = async (data: CreateNewsData | UpdateNewsData, status: NewsStatus) => {
    setIsSubmitting(true);
    setError(undefined);
    const isEdit = Boolean(data.id);

    try {
      const validation = validateNews(data);
      if (!validation.success) {
        throw new Error(validation.error.issues[0].message);
      }

      if (isEdit) {
        const { id, ...updateData } = data as UpdateNewsData & { id: string };
        await newsApi.update(id, { ...updateData, status });
        navigate('/dashboard');
      } else {
        await newsApi.createNews({ ...data, status });
        if (isAdmin) {
          navigate('/dashboard');
        } else {
          setShowSuccess(true);
        }
      }

    } catch (err) {
      logger.error('News submission error:', err);
      setError(err instanceof Error ? err.message : 'Erreur lors de la création de l\'article');
      throw err;
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    submitNews,
    isSubmitting,
    error,
    showSuccess,
    setShowSuccess
  };
}