import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { Modal } from './Modal';

interface ConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  confirmLabel?: string;
  isDangerous?: boolean;
}

export function ConfirmModal({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmLabel = "Supprimer",
  isDangerous = true
}: ConfirmModalProps) {
  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      className="border-solid border-4 border-red-500"
    >
      <div className="text-center space-y-8">
        <div className="inline-flex items-center justify-center p-6 bg-red-100 rounded-full">
          <AlertTriangle className="w-12 h-12 text-red-600" />
        </div>

        <div className="space-y-4">
          <h3 className="text-2xl font-display uppercase text-retro-purple dark:text-white">
            {title}
          </h3>

          <p className="text-retro-purple/80 dark:text-white/80">
            {message}
          </p>
        </div>

        <div className="flex justify-center gap-4 pt-4">
          <button
            onClick={onClose}
            className="px-6 py-3 text-base font-display uppercase text-retro-purple dark:text-white hover:text-retro-pink transition-colors"
          >
            Annuler
          </button>
          <button
            onClick={onConfirm}
            className="px-6 py-3 text-base font-display uppercase rounded-lg bg-red-600 text-white hover:bg-red-700"
          >
            {confirmLabel}
          </button>
        </div>
      </div>
    </Modal>
  );
}