import { createClient } from '@supabase/supabase-js';
import type { Database } from '../types/database.types';
import { logger } from '../utils/debug';

// Get environment variables
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

// Validate environment variables
if (!supabaseUrl || !supabaseAnonKey) {
  const missingVars = [];
  if (!supabaseUrl) missingVars.push('VITE_SUPABASE_URL');
  if (!supabaseAnonKey) missingVars.push('VITE_SUPABASE_ANON_KEY');
  
  const error = `Missing required environment variables: ${missingVars.join(', ')}. Please check your .env file.`;
  logger.error(error);
  throw new Error(error);
}

// Validate URL format
if (!supabaseUrl.startsWith('https://') || !supabaseUrl.endsWith('.supabase.co')) {
  const error = `Invalid Supabase URL format. URL must start with 'https://' and end with '.supabase.co'`;
  logger.error(error);
  throw new Error(error);
}

// Create Supabase client
export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false
  }
});

// Test connection
supabase.auth.getSession().then(({ data, error }) => {
  if (error) {
    logger.error('Supabase connection error:', error);
  } else {
    logger.info('Supabase connected successfully');
  }
});