import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { User, LogOut, Settings, LayoutDashboard } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { cn } from '../../utils/cn';

export function UserMenu() {
  const { user, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const isAdmin = user?.role === 'admin';

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  if (!user) return null;

  const displayName = user.firstName && user.lastName 
    ? `${user.firstName} ${user.lastName[0]}.`
    : 'Utilisateur';

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={cn(
          "flex items-center gap-2 px-3 py-2 rounded-lg",
          "text-retro-purple dark:text-white",
          "hover:bg-retro-purple/5 dark:hover:bg-white/5",
          "transition-colors"
        )}
      >
        <User className="w-5 h-5" />
        <span className="hidden md:block">{displayName}</span>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-retro-purple rounded-lg shadow-xl border border-retro-purple/10 dark:border-white/10">
          
          <div className="p-2 border-b border-retro-purple/10 dark:border-white/10">
            <p className="text-sm px-4 pt-3 font-medium text-retro-purple dark:text-white">
              {displayName}
            </p>
            <p className="text-xs px-4 text-retro-purple/60 dark:text-white/60">
              {user.email}
            </p>
          </div>
          
          <div className="px-2 py-4">
            <Link
              to="/settings"
              className="flex items-center gap-2 px-4 py-2 text-sm text-retro-purple dark:text-white hover:bg-retro-purple/5 dark:hover:bg-white/5 rounded-md"
              onClick={() => setIsOpen(false)}
            >
              <Settings className="w-4 h-4" />
              Paramètres
            </Link>
            {isAdmin && (
              <Link
                to="/dashboard"
                className="flex items-center gap-2 px-4 py-2 text-sm text-retro-purple dark:text-white hover:bg-retro-purple/5 dark:hover:bg-white/5 rounded-md"
                onClick={() => setIsOpen(false)}
              >
                <LayoutDashboard className="w-4 h-4" />
                Dashboard
              </Link>
            )}
            
            <button
              onClick={handleLogout}
              className="w-full flex items-center gap-2 px-4 py-2 text-sm text-red-600 hover:bg-red-50 dark:hover:bg-red-900/10 rounded-md"
            >
              <LogOut className="w-4 h-4" />
              Déconnexion
            </button>
          </div>
        </div>
      )}
    </div>
  );
}