import React, { useState } from 'react';
import { Search as SearchIcon, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { Input } from './Input';

export function Search() {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (query.trim()) {
      navigate(`/search?q=${encodeURIComponent(query)}`);
      setIsOpen(false);
      setQuery('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setQuery('');
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="search-button p-2 text-retro-purple/70 dark:text-white/70 hover:text-retro-pink transition-colors rounded-lg hover:bg-retro-purple/5 dark:hover:bg-white/5"
        aria-label="Rechercher"
      >
        <SearchIcon className="w-5 h-5" />
      </button>

      {isOpen && createPortal(
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div 
            className="fixed inset-0 bg-black/50 backdrop-blur-sm" 
            onClick={handleClose}
          />
          
          <div className="relative min-h-screen flex items-center justify-center p-8">
            <div className="relative bg-white dark:bg-retro-purple/95 rounded-lg w-full max-w-md p-8 shadow-xl border-4 border-retro-pink">
              <button
                onClick={handleClose}
                className="absolute -top-3 -right-3 p-2 bg-white dark:bg-retro-purple rounded-full shadow-lg text-retro-purple/60 dark:text-white/60 hover:text-retro-pink transition-colors"
              >
                <X className="w-6 h-6" />
              </button>

              <form onSubmit={handleSearch} className="space-y-6">
                <div className="flex justify-center mb-8">
                  <div className="p-6 bg-pink-100 dark:bg-pink-900/20 rounded-full">
                    <SearchIcon className="w-12 h-12 text-retro-pink" />
                  </div>
                </div>

                <Input
                  type="search"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Rechercher..."
                  autoFocus
                />
              </form>
            </div>
          </div>
        </div>,
        document.body
      )}
    </>
  );
}