import React from 'react';
import { Link } from 'react-router-dom';
import { Home, Wind } from 'lucide-react';

export default function Error404Page() {
  return (
    <div className="bg-white dark:bg-retro-purple/95">
      <section className="pt-60 pb-40 px-4 relative overflow-hidden bg-gradient-to-b from-white dark:from-retro-purple to-retro-purple/5 dark:to-retro-purple/20">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,#FF69B4_0%,transparent_70%)] opacity-10"></div>
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,#00B4D8_0%,transparent_70%)] opacity-10"></div>
        
        <div className="max-w-[800px] mx-auto relative z-10 text-center">
          <div className="flex justify-center mb-8">
            <Wind className="w-24 h-24 text-retro-blue animate-pulse" />
          </div>

          <h1 className="text-4xl md:text-6xl font-display uppercase mb-6 text-retro-purple dark:text-white retro-shadow">
            404 - Page introuvable
          </h1>
          
          <p className="text-xl text-retro-purple/80 dark:text-white/80 mb-12">
            Oups ! Il semble que cette page se soit envolée... Peut-être un coup de thermique ?
          </p>

          <Link 
            to="/"
            className="btn-primary inline-flex items-center gap-2 hover:gap-3 transition-all duration-300"
          >
            <Home className="w-5 h-5" />
            Retour à l'accueil
          </Link>
        </div>
      </section>
    </div>
  );
}