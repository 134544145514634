import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCurrentUser, signIn, signUp, signOut } from '../lib/auth';
import { logger } from '../utils/debug';
import type { User } from '../types/auth';

interface AuthContextType {
  user: User | null;
  isLoading: boolean;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCurrentUser().then(user => {
      setUser(user);
      setIsLoading(false);
    });
  }, []);

  const login = async (email: string, password: string) => {
    try {
      await signIn(email, password);
      const user = await getCurrentUser();
      setUser(user);
    } catch (error) {
      logger.error('Login error:', error);
      throw error;
    }
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    try {
      await signUp(email, password, firstName, lastName);
      const user = await getCurrentUser();
      setUser(user);
    } catch (error) {
      logger.error('Register error:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      setUser(null); // Réinitialiser l'état immédiatement
      await signOut();
    } catch (error) {
      logger.error('Logout error:', error);
      // On ne relance pas l'erreur car l'état utilisateur est déjà réinitialisé
    }
  };

  return (
    <AuthContext.Provider value={{ user, isLoading, login, register, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }
  return context;
}