import { useState, useEffect } from 'react';
import { supabase } from '../../../lib/supabase';
import { logger } from '../../../utils/debug';
import { transformNewsData } from '../utils/transform';
import type { News } from '../types';

interface NewsEdit {
  news: News | null;
  isLoading: boolean;
  error: string | null;
}

export function useNewsEdit(id: string): NewsEdit {
  const [news, setNews] = useState<News | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchNews() {
      try {
        setIsLoading(true);
        setError(null);

        const { data: currentNews, error: currentError } = await supabase
          .from('news')
          .select(`
            *,
            profiles:author_id (
              first_name,
              last_name
            )
          `)
          .eq('id', id)
          .single();

        if (currentError) throw currentError;
        if (!currentNews) throw new Error('Article non trouvé');

        setNews(transformNewsData(currentNews));

      } catch (err) {
        logger.error('News fetch error:', err);
        setError(err instanceof Error ? err.message : 'Erreur lors du chargement de l\'article');
      } finally {
        setIsLoading(false);
      }
    }

    if (id) {
      fetchNews();
    }
  }, [id]);

  return { news, isLoading, error };
}