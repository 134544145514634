import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { cn } from '../../../utils/cn';

interface FormFieldProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label: string;
  error?: string;
  type: string;
  rows?: number;
  validateStatus?: (value: string) => string | undefined;
}

export function FormField({ 
  label, 
  error, 
  type, 
  rows, 
  validateStatus,
  value,
  onChange,
  ...props 
}: FormFieldProps) {
  const [localError, setLocalError] = React.useState<string>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e);
    }
    
    if (validateStatus) {
      const validationError = validateStatus(e.target.value);
      setLocalError(validationError);
    }
  };

  const displayError = error || localError;

  return (
    <div>
      <label htmlFor={props.id} className="block text-sm font-display uppercase text-retro-purple dark:text-white mb-2">
        {label}
      </label>
      {type === 'textarea' ? (
        <textarea
          {...props as React.TextareaHTMLAttributes<HTMLTextAreaElement>}
          value={value}
          onChange={handleChange}
          className={cn(
            'w-full px-4 py-3 rounded-lg bg-white dark:bg-retro-purple/30 border-2',
            displayError ? 'border-red-500' : 'border-retro-purple/10 dark:border-white/10',
            'text-retro-purple dark:text-white',
            'focus:outline-none focus:border-retro-pink dark:focus:border-retro-pink transition-colors'
          )}
          rows={rows}
        />
      ) : (
        <input
          {...props as React.InputHTMLAttributes<HTMLInputElement>}
          type={type}
          value={value}
          onChange={handleChange}
          className={cn(
            'w-full px-4 py-3 rounded-lg bg-white dark:bg-retro-purple/30 border-2',
            displayError ? 'border-red-500' : 'border-retro-purple/10 dark:border-white/10',
            'text-retro-purple dark:text-white',
            'focus:outline-none focus:border-retro-pink dark:focus:border-retro-pink transition-colors'
          )}
        />
      )}
      {displayError && (
        <div className="mt-2 flex items-center gap-2 text-red-600 dark:text-red-400">
          <AlertTriangle className="w-4 h-4" />
          <p className="text-sm">{displayError}</p>
        </div>
      )}
    </div>
  );
}