import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { Modal } from './Modal';

interface AlertModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
}

export function AlertModal({ isOpen, onClose, title, message }: AlertModalProps) {
  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      className="border-solid border-4 border-orange-500"
    >
      <div className="text-center space-y-8">
        <div className="inline-flex items-center justify-center p-6 bg-orange-100 rounded-full">
          <AlertTriangle className="w-12 h-12 text-orange-600" />
        </div>

        <div className="space-y-4">
          <h2 className="text-2xl font-display uppercase text-retro-purple dark:text-white">
            {title}
          </h2>
          <p className="text-retro-purple/80 dark:text-white/80">
            {message}
          </p>
        </div>
      </div>
    </Modal>
  );
}