import React from 'react';
import { X } from 'lucide-react';
import { cn } from '../../../utils/cn';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  title?: string;
}

export function Modal({ isOpen, onClose, children, className, title }: ModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div 
        className="fixed inset-0 bg-black/50 backdrop-blur-sm" 
        onClick={onClose}
      />
      
      <div className="relative min-h-screen flex items-center justify-center p-8">
        <div className={cn(
          "relative bg-white dark:bg-retro-purple/95 rounded-lg w-full max-w-md p-8 shadow-xl",
          className
        )}>
          <button
            onClick={onClose}
            className="absolute -top-3 -right-3 p-2 bg-white dark:bg-retro-purple rounded-full shadow-lg text-retro-purple/60 dark:text-white/60 hover:text-retro-pink transition-colors"
          >
            <X className="w-6 h-6" />
          </button>

          {title && (
            <h2 className="text-2xl font-display uppercase text-center text-retro-purple dark:text-white mb-8">
              {title}
            </h2>
          )}

          {children}
        </div>
      </div>
    </div>
  );
}