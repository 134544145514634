import React from 'react';
import { NEWS_CATEGORIES } from '../types';

interface CategorySelectProps {
  value: string;
  onChange: (value: string) => void;
  error?: string;
}

export function CategorySelect({ value, onChange, error }: CategorySelectProps) {
  return (
    <div>
      <label className="block text-sm font-display uppercase text-retro-purple dark:text-white mb-2">
        Catégorie *
      </label>
      <select
        required
        className={`w-full px-4 py-3 rounded-lg bg-white dark:bg-retro-purple/30 
                   border-2 ${error ? 'border-red-500' : 'border-retro-purple/10 dark:border-white/10'}
                   text-retro-purple dark:text-white
                   focus:outline-none focus:border-retro-pink transition-colors`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">Sélectionnez une catégorie</option>
        {NEWS_CATEGORIES.map((category) => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </select>
      {error && (
        <p className="mt-1 text-sm text-red-500">{error}</p>
      )}
    </div>
  );
}