import { useState, useEffect, useCallback } from 'react';
import { newsApi } from '../api/newsApi';
import { logger } from '../../../utils/debug';
import type { News } from '../types';

export function useNews(includeAll = false) {
  const [news, setNews] = useState<News[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchNews = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const data = includeAll 
        ? await newsApi.getAll()
        : await newsApi.getPublished();
      setNews(data);
    } catch (err) {
      logger.error('News fetch error:', err);
      setError(err instanceof Error ? err.message : 'Erreur lors du chargement des actualités');
      setNews([]);
    } finally {
      setIsLoading(false);
    }
  }, [includeAll]);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  return { news, isLoading, error, refetch: fetchNews };
}