import { supabase } from './supabase';
import type { User } from '../types/auth';
import { logger } from '../utils/debug';

export async function getCurrentUser(): Promise<User | null> {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session?.user) return null;

    const { data: profile } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', session.user.id)
      .single();

    if (!profile) return null;

    return {
      id: session.user.id,
      email: session.user.email!,
      firstName: profile.first_name,
      lastName: profile.last_name,
      role: profile.role || 'author',
      displayName: `${profile.first_name || ''} ${profile.last_name || ''}`.trim() || 'Utilisateur'
    };
  } catch (error) {
    logger.error('Error getting current user:', error);
    return null;
  }
}

export async function signIn(email: string, password: string) {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password
  });

  if (error) throw error;
  return data;
}

export async function signUp(email: string, password: string, firstName: string, lastName: string) {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
    options: {
      data: {
        first_name: firstName,
        last_name: lastName
      }
    }
  });

  if (error) throw error;

  if (data.user) {
    const { error: profileError } = await supabase
      .from('profiles')
      .insert([
        {
          id: data.user.id,
          email: email,
          first_name: firstName,
          last_name: lastName,
          role: 'author'
        }
      ]);

    if (profileError) throw profileError;
  }

  return data;
}

export async function signOut() {
  try {
    // Nettoyage des données locales d'abord
    localStorage.removeItem('sb-' + import.meta.env.VITE_SUPABASE_PROJECT_ID + '-auth-token');
    sessionStorage.clear();
    
    // Déconnexion de Supabase
    const { error } = await supabase.auth.signOut({
      scope: 'local'
    });
    
    if (error) {
      logger.error('Supabase signOut error:', error);
      // On continue même en cas d'erreur pour assurer un nettoyage complet
    }

    // Forcer le rafraîchissement du client Supabase
    await supabase.auth.refreshSession();
    
    logger.info('User logged out successfully');
  } catch (error) {
    logger.error('Logout error:', error);
    // On ne relance pas l'erreur pour éviter les erreurs en cascade
  }
}