export const mainNavigationConfig = {
  actualites: {
    label: 'Actualité',
    items: [
      { label: 'Lire les articles', href: '/actualites' },
      { label: 'Créer un article', href: '/news-submit' },
      { label: 'Guide de rédaction', href: '/news-writing-faq' }
    ]
  },
  materiel: {
    label: 'Matériel',
    items: [
      { label: 'Voir les équipements', href: '/materiel' },
      { label: 'Vendre un équipement', href: '/materiel/submit' }
    ]
  },
  boutique: {
    label: 'Boutique',
    href: '/boutique'
  }
};