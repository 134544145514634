import React from 'react';
import { RefreshCw } from 'lucide-react';

interface DashboardHeroProps {
  onRefresh: () => void;
  isLoading: boolean;
}

export function DashboardHero({ onRefresh, isLoading }: DashboardHeroProps) {
  return (
    <section className="pt-36 pb-20 p-12 relative overflow-hidden bg-gradient-to-b from-white dark:from-retro-purple to-retro-purple/5 dark:to-retro-purple/20">
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,#FF69B4_0%,transparent_70%)] opacity-10"></div>
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,#00B4D8_0%,transparent_70%)] opacity-10"></div>
      
      <div className="max-w-7xl mx-auto relative z-10">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-4xl md:text-6xl font-display uppercase mb-6 text-retro-purple dark:text-white retro-shadow">
              Dashboard de publication
            </h1>
            <p className="text-xl text-retro-purple/80 dark:text-white/80 max-w-2xl">
              Gérez et modérez les articles soumis par la communauté
            </p>
            <button
              onClick={onRefresh}
              disabled={isLoading}
              className="btn-secondary py-3 px-6 inline-flex items-center gap-2"
            >
              <RefreshCw className={`w-5 h-5 ${isLoading ? 'animate-spin' : ''}`} />
              Actualiser
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}