export const NEWS_CATEGORIES = [
  'Compétition',
  'Evènement',
  'Interview',
  'Localité/Site',
  'Matériel',
  'Récit',
  'Record'
] as const;

export type NewsCategory = typeof NEWS_CATEGORIES[number];
export type NewsStatus = 'draft' | 'submitted' | 'published' | 'archived';

export interface NewsValidationErrors {
  title?: string;
  content?: string;
  category?: string;
  main_image?: string;
  status?: string;
}

export interface NewsFormState {
  data: Partial<CreateNewsData | UpdateNewsData>;
  errors: NewsValidationErrors;
  status: NewsStatus;
  isSubmitting: boolean;
}

export interface News {
  id: string;
  title: string;
  content: string;
  category: NewsCategory;
  main_image: string;
  additional_images?: string[];
  author_id: string;
  author_name: string;
  status: NewsStatus;
  created_at: string;
  updated_at: string;
  excerpt?: string;
  published_date?: string;
  original_article_url?: string;
}

export interface CreateNewsData {
  title: string;
  content: string;
  category: NewsCategory;
  main_image: string;
  additional_images?: string[];
  author_id: string;
  status: NewsStatus;
  published_date?: string;
  original_article_url?: string;
  excerpt?: string;
}

export interface UpdateNewsData extends Omit<CreateNewsData, 'author_id'> {
  id: string;
}

export interface NewsStatusTransition {
  from: NewsStatus;
  to: NewsStatus[];
  isAllowed: (isAdmin: boolean) => boolean;
}

export const NEWS_STATUS_TRANSITIONS: NewsStatusTransition[] = [
  {
    from: 'draft',
    to: ['submitted'],
    isAllowed: () => true
  },
  {
    from: 'submitted',
    to: ['draft'],
    isAllowed: () => true
  },
  {
    from: 'draft',
    to: ['published', 'archived'],
    isAllowed: (isAdmin) => isAdmin
  },
  {
    from: 'submitted',
    to: ['published', 'archived'],
    isAllowed: (isAdmin) => isAdmin
  },
  {
    from: 'published',
    to: ['archived'],
    isAllowed: (isAdmin) => isAdmin
  },
  {
    from: 'archived',
    to: ['published'],
    isAllowed: (isAdmin) => isAdmin
  }
];