import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useNews } from '../../features/news/hooks/useNews';
import { newsApi } from '../../features/news/api/newsApi';
import { DashboardNewsTable } from '../../features/dashboard/components/DashboardNewsTable';
import { DashboardHero } from '../../features/dashboard/components/DashboardHero';
import { ConfirmModal } from '../../components/ui/modals/ConfirmModal';
import type { NewsStatus } from '../../features/news/types';

export default function DashboardPage() {
  const { user } = useAuth();
  const isAdmin = user?.role === 'admin';
  const { news, isLoading, error, refetch } = useNews(true);
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState<{ isOpen: boolean; newsId: string | null }>({
    isOpen: false,
    newsId: null
  });

  const handleUpdateStatus = async (id: string, status: NewsStatus) => {
    try {
      if (!isAdmin) {
        throw new Error('Seuls les administrateurs peuvent modifier le statut des articles');
      }
      await newsApi.updateStatus(id, status);
      await refetch();
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  };

  const handleDelete = async (id: string) => {
    setDeleteModal({ isOpen: true, newsId: id });
  };

  const confirmDelete = async () => {
    if (!deleteModal.newsId) return;
    try {
      await newsApi.delete(deleteModal.newsId);
      await refetch();
      setDeleteModal({ isOpen: false, newsId: null });
    } catch (error) {
      console.error('Failed to delete news:', error);
    }
  };

  const handleEdit = (id: string, currentStatus: NewsStatus) => {
    navigate(`/dashboard/news/edit/${id}`);
  };

  return (
    <div className="min-h-screen bg-white dark:bg-retro-purple/95">
      <DashboardHero onRefresh={refetch} isLoading={isLoading} />
      
      <div className="py-16 md:py-32 px-2 md:px-4 bg-white dark:bg-retro-purple/90 w-full max-w-7xl mx-auto">
        <div className="space-y-6">
          {error && (
            <div className="p-4 bg-red-100 border border-red-400 text-red-700 rounded">
              {error}
            </div>
          )}

          <DashboardNewsTable
            news={news}
            isLoading={isLoading}
            onUpdateStatus={handleUpdateStatus}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        </div>
      </div>

      <ConfirmModal
        isOpen={deleteModal.isOpen}
        onClose={() => setDeleteModal({ isOpen: false, newsId: null })}
        onConfirm={confirmDelete}
        title="Supprimer l'article"
        message="Êtes-vous sûr de vouloir supprimer cet article ? Cette action est irréversible."
      />
    </div>
  );
}