import { logger } from './debug';

/**
 * Environment variable validation utility
 */
export function validateEnv() {
  const required = [
    'VITE_SUPABASE_URL',
    'VITE_SUPABASE_ANON_KEY'
  ];

  const missing = required.filter(key => !import.meta.env[key]);

  if (missing.length > 0) {
    logger.error('Missing environment variables', missing);
    throw new Error(
      `Missing required environment variables:\n${missing.join('\n')}\n` +
      'Please check your .env file or create one based on .env.example'
    );
  }

  // Log successful validation in development
  if (import.meta.env.DEV) {
    logger.info('Environment variables validated successfully');
  }
}

/**
 * Get environment variable with type safety
 */
export function getEnvVar(key: string): string {
  const value = import.meta.env[key];
  if (!value) {
    throw new Error(`Missing environment variable: ${key}`);
  }
  return value;
}