import React from 'react';
import { Link } from 'react-router-dom';
import { PageContainer } from '../layout/PageContainer';

const HomeHero = () => {
  return (
    <section className="min-h-[85vh] pt-24 md:pt-32 pb-16 md:pb-24 relative overflow-hidden bg-gradient-to-b from-white dark:from-retro-purple to-retro-purple/5 dark:to-retro-purple/20 flex items-center">
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,#FFD93D_0%,transparent_70%)] opacity-10"></div>
      <PageContainer className="relative z-10">
        <div className="flex flex-col md:flex-row items-center gap-8 md:gap-16">
          <div className="flex-1">
            <div className="relative flex flex-col items-center md:items-start">
              <h1 className="text-5xl md:text-8xl font-display uppercase mb-4 md:mb-8 leading-tight text-retro-purple dark:text-white retro-shadow">
                Vola<span className="rainbow-text">volo</span>
                <br />
                <span className="text-retro-orange">Parapente</span>
              </h1>
              <p className="text-lg md:text-xl px-6 md:px-1 mb-8 md:mb-12 text-retro-purple/80 dark:text-white/80">
                Destination ultime pour prendre l'ascendant sur l'actualité et atterir sur du matériel de parapente. Site réservé à la communauté des passionnés.
              </p>
              <div className="flex flex-col md:flex-row items-center gap-3 md:gap-6 w-full md:w-auto max-w-[80%] mx-auto md:mx-0">
                <Link to="/actualites" className="w-auto btn-primary">
                  Lire les news
                </Link>
                <Link to="/materiel" className="w-auto btn-secondary">
                  Voir le matos
                </Link>
                <Link to="/boutique" className="w-auto btn-tertiary">
                  Faire un cadeau
                </Link>
              </div>
            </div>
          </div>
          <div className="hidden md:block md:w-1/3">
            <div className="relative">
              <img
                src="https://images.unsplash.com/photo-1503152578173-c86dbe766c71?auto=format&fit=crop&w=1600&q=80"
                alt="Aventure en parapente"
                className="w-full h-[440px] object-cover rounded-lg shadow-2xl"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-retro-pink via-retro-orange to-retro-blue opacity-20 mix-blend-overlay rounded-lg"></div>
            </div>
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default HomeHero;