import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Moon, Sun, Search as SearchIcon, User } from 'lucide-react';
import { useTheme } from '../../context/ThemeContext';
import { useAuth } from '../../context/AuthContext';
import { DesktopNavDropdown } from '../navigation/DesktopNavDropdown';
import { Search } from '../ui/Search';
import { MobileNavDropdown } from '../navigation/MobileNavDropdown';
import { mainNavigationConfig } from '../navigation/navigationConfig';
import { PageContainer } from './PageContainer';
import { UserMenu } from '../auth/UserMenu';

export default function MainNavigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isDark, toggleTheme } = useTheme();
  const { user } = useAuth();
  const location = useLocation();

  const isActive = (path: string) => location.pathname === path;
  const isActiveDropdown = (paths: string[]) => paths.some(path => location.pathname.startsWith(path));

  const renderThemeIcon = () => isDark ? <Sun className="w-5 h-5" /> : <Moon className="w-5 h-5" />;

  return (
    <>
      <nav className="fixed w-full z-50 bg-white/80 dark:bg-retro-purple/95 backdrop-blur-md border-b border-retro-purple/10">
        <PageContainer>
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <div className="flex-shrink-0">
              <Link to="/" className="flex items-center">
                <span className="text-2xl font-display uppercase tracking-wider text-retro-purple dark:text-white">
                  Vola<span className="rainbow-text">volo</span>
                </span>
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center justify-center flex-1">
              <div className="flex items-center space-x-8">
                <DesktopNavDropdown
                  label={mainNavigationConfig.actualites.label}
                  items={mainNavigationConfig.actualites.items}
                  isActive={isActiveDropdown(['/actualites', '/iframe'])}
                />
                <DesktopNavDropdown
                  label={mainNavigationConfig.materiel.label}
                  items={mainNavigationConfig.materiel.items}
                  isActive={isActiveDropdown(['/materiel'])}
                />
                <Link
                  to={mainNavigationConfig.boutique.href}
                  className={`text-retro-purple dark:text-white hover:text-retro-pink transition-colors font-display uppercase tracking-wider ${
                    isActive(mainNavigationConfig.boutique.href) ? 'text-retro-pink' : ''
                  }`}
                >
                  {mainNavigationConfig.boutique.label}
                </Link>
              </div>
            </div>

            {/* Desktop Actions */}
            <div className="hidden md:flex items-center gap-2">
              <button
                onClick={toggleTheme}
                className="p-2 text-retro-purple/70 dark:text-white/70 hover:text-retro-pink transition-colors rounded-lg hover:bg-retro-purple/5 dark:hover:bg-white/5"
                aria-label="Basculer le thème"
              >
                {renderThemeIcon()}
              </button>
              <Search />
              {user ? (
                <UserMenu />
              ) : (
                <Link
                  to="/auth"
                  className="p-2 text-retro-purple/70 dark:text-white/70 hover:text-retro-pink transition-colors rounded-lg hover:bg-retro-purple/5 dark:hover:bg-white/5"
                >
                  <User className="w-5 h-5" />
                </Link>
              )}
            </div>

            {/* Mobile Menu Button */}
            <div className="flex md:hidden">
              <button 
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="p-2 text-retro-purple/70 dark:text-white/70 hover:text-retro-pink transition-colors rounded-lg hover:bg-retro-purple/5 dark:hover:bg-white/5"
                aria-label="Menu"
              >
                <Menu className="w-5 h-5" />
              </button>
            </div>
          </div>
        </PageContainer>
      </nav>

      {/* Mobile Menu Overlay */}
      {isMenuOpen && (
        <div 
          className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40 md:hidden"
          onClick={() => setIsMenuOpen(false)}
        />
      )}

      {/* Mobile Menu */}
      <div 
        className={`fixed top-0 right-0 h-full w-80 bg-white dark:bg-retro-purple shadow-2xl transform transition-transform duration-300 ease-in-out z-50 md:hidden ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="flex flex-col h-full pt-20 px-4">
          {/* Close Button */}
          <button
            onClick={() => setIsMenuOpen(false)}
            className="absolute top-4 right-4 p-2 text-retro-purple/70 dark:text-white/70 hover:text-retro-pink transition-colors rounded-lg hover:bg-retro-purple/5 dark:hover:bg-white/5"
            aria-label="Fermer le menu"
          >
            <Menu className="w-6 h-6" />
          </button>

          {/* Mobile Navigation Links */}
          <div className="space-y-2">
            <Link 
              to="/"
              onClick={() => setIsMenuOpen(false)}
              className={`block py-4 px-3 text-lg font-display uppercase tracking-wider rounded-lg
                       hover:bg-retro-purple/5 dark:hover:bg-white/5 transition-colors
                       ${isActive('/') 
                         ? 'text-retro-pink' 
                         : 'text-retro-purple dark:text-white hover:text-retro-pink'}`}
            >
              Accueil
            </Link>
            
            <MobileNavDropdown
              label={mainNavigationConfig.actualites.label}
              items={mainNavigationConfig.actualites.items}
              isActive={isActiveDropdown(['/actualites', '/iframe'])}
              onItemClick={() => setIsMenuOpen(false)}
            />
            
            <MobileNavDropdown
              label={mainNavigationConfig.materiel.label}
              items={mainNavigationConfig.materiel.items}
              isActive={isActiveDropdown(['/materiel'])}
              onItemClick={() => setIsMenuOpen(false)}
            />
            
            <Link
              to={mainNavigationConfig.boutique.href}
              onClick={() => setIsMenuOpen(false)}
              className={`block py-4 px-3 text-lg font-display uppercase tracking-wider rounded-lg
                       hover:bg-retro-purple/5 dark:hover:bg-white/5 transition-colors
                       ${isActive(mainNavigationConfig.boutique.href)
                         ? 'text-retro-pink'
                         : 'text-retro-purple dark:text-white hover:text-retro-pink'}`}
            >
              {mainNavigationConfig.boutique.label}
            </Link>

            {/* Mobile Actions */}
            <div className="border-t border-retro-purple/10 dark:border-white/10 pt-8 mt-8">
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-3 w-full py-4 px-3 text-base font-display uppercase tracking-wider rounded-lg
                           text-retro-purple/70 dark:text-white/70 hover:text-retro-pink
                           hover:bg-retro-purple/5 dark:hover:bg-white/5 transition-colors cursor-pointer"
                  onClick={toggleTheme}
                >
                  {renderThemeIcon()}
                  <span>Thème</span>
                </div>

                <div 
                  className="flex items-center gap-3 w-full py-4 px-3 text-base font-display uppercase tracking-wider rounded-lg
                           text-retro-purple/70 dark:text-white/70 hover:text-retro-pink
                           hover:bg-retro-purple/5 dark:hover:bg-white/5 transition-colors cursor-pointer"
                  onClick={() => {
                    setIsMenuOpen(false);
                    document.querySelector<HTMLButtonElement>('.search-button')?.click();
                  }}
                >
                  <SearchIcon className="w-5 h-5" />
                  <span>Recherche</span>
                </div>

                {user ? (
                  <div className="flex items-center gap-3 py-4 px-3">
                    <UserMenu />
                  </div>
                ) : (
                  <Link
                    to="/auth"
                    onClick={() => setIsMenuOpen(false)}
                    className="flex items-center gap-3 py-4 px-3 text-base font-display uppercase tracking-wider rounded-lg
                             text-retro-purple/70 dark:text-white/70 hover:text-retro-pink
                             hover:bg-retro-purple/5 dark:hover:bg-white/5 transition-colors"
                  >
                    <User className="w-5 h-5" />
                    <span>Connexion</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}