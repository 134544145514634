import React from 'react';
import { Eye, EyeOff, Clock, Archive, Edit, Trash2, Image, Link as LinkIcon } from 'lucide-react';
import { cn } from '../../../utils/cn';
import type { News, NewsStatus } from '../../news/types';

interface DashboardNewsTableProps {
  news: News[];
  onUpdateStatus: (id: string, status: NewsStatus) => void;
  onDelete: (id: string) => void;
  onEdit: (id: string, currentStatus: NewsStatus) => void;
  isLoading: boolean;
}

const statusConfig = {
  published: {
    icon: Eye,
    label: 'Publié',
    color: 'text-green-600 dark:text-green-400'
  },
  submitted: {
    icon: Clock,
    label: 'Soumis',
    color: 'text-yellow-600 dark:text-yellow-400'
  },
  draft: {
    icon: EyeOff,
    label: 'Brouillon',
    color: 'text-orange-600 dark:text-orange-400'
  },
  archived: {
    icon: Archive,
    label: 'Archivé',
    color: 'text-gray-600 dark:text-gray-400'
  }
} as const;

export function DashboardNewsTable({ 
  news, 
  onUpdateStatus, 
  onDelete, 
  onEdit, 
  isLoading 
}: DashboardNewsTableProps) {
  const handleEdit = (id: string, currentStatus: NewsStatus) => {
    // Passer automatiquement en brouillon si l'article n'est pas déjà en brouillon
    if (currentStatus !== 'draft') {
      onUpdateStatus(id, 'draft');
    }
    onEdit(id, currentStatus);
  };

  if (isLoading) {
    return (
      <div className="space-y-4">
        {[1, 2, 3].map((n) => (
          <div key={n} className="bg-white dark:bg-retro-purple/50 rounded-lg p-4 animate-pulse">
            <div className="h-20 bg-retro-purple/10 dark:bg-white/10 rounded-lg"></div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="overflow-x-auto rounded-lg border border-retro-purple/10 dark:border-white/10">
      <table className="w-full">
        <thead className="bg-retro-purple/10 dark:bg-white/10">
          <tr>
            <th className="px-6 py-4 text-left text-sm font-display uppercase text-retro-purple dark:text-white">Article</th>
            <th className="px-6 py-4 text-center text-sm font-display uppercase text-retro-purple dark:text-white w-24">Images</th>
            <th className="px-6 py-4 text-left text-sm font-display uppercase text-retro-purple dark:text-white w-40">Auteur</th>
            <th className="px-6 py-4 text-center text-sm font-display uppercase text-retro-purple dark:text-white w-40">Source</th>
            <th className="px-6 py-4 text-center text-sm font-display uppercase text-retro-purple dark:text-white w-40">Statut</th>
            <th className="px-6 py-4 text-right text-sm font-display uppercase text-retro-purple dark:text-white w-40">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-retro-purple/15 dark:divide-white/15">
          {news.map((item) => (
            <tr key={item.id} className="hover:bg-retro-purple/5 dark:hover:bg-white/5">
              <td className="px-6 py-4">
                <div className="flex items-center gap-4">
                  <div className="relative w-[120px] h-[80px] rounded-lg overflow-hidden flex-shrink-0">
                    {item.main_image ? (
                      <img
                        src={item.main_image}
                        alt={item.title}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full bg-retro-purple/10 dark:bg-white/10 flex items-center justify-center">
                        <span className="text-retro-purple/40 dark:text-white/40 text-sm">No image</span>
                      </div>
                    )}
                  </div>
                  <div className="flex-1 min-w-0">
                    <h4 className="text-base font-medium text-retro-purple dark:text-white line-clamp-2">
                      {item.title}
                    </h4>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4">
                <div className="flex items-center justify-center gap-2 text-sm text-retro-purple/80 dark:text-white/80">
                  <Image className="w-4 h-4" />
                  <span>
                    {((item.additional_images || []).length) + (item.main_image ? 1 : 0)}
                  </span>
                </div>
              </td>
              <td className="px-6 py-4 text-sm text-retro-purple/80 dark:text-white/80">
                {item.author_name || 'Anonyme'}
              </td>
              <td className="px-6 py-4">
                <div className="flex items-center justify-center">
                  {item.original_article_url ? (
                    <a
                      href={item.original_article_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-2 text-retro-purple/60 dark:text-white/60 hover:text-retro-pink transition-colors"
                      title="Voir l'article source"
                    >
                      <LinkIcon className="w-4 h-4" />
                    </a>
                  ) : (
                    <span className="text-retro-purple/40 dark:text-white/40">-</span>
                  )}
                </div>
              </td>
              <td className="px-6 py-4">
                <div className="flex flex-col">
                  {(Object.entries(statusConfig) as [NewsStatus, typeof statusConfig[keyof typeof statusConfig]][]).map(([status, config]) => (
                    <label 
                      key={status}
                      className={cn(
                        "flex items-center gap-2 px-3 py-1 rounded-lg cursor-pointer",
                        "hover:bg-retro-purple/5 dark:hover:bg-white/5",
                        item.status === status && "bg-retro-purple/5 dark:bg-white/5"
                      )}
                    >
                      <input
                        type="radio"
                        name={`status-${item.id}`}
                        value={status}
                        checked={item.status === status}
                        onChange={() => onUpdateStatus(item.id, status)}
                        className="sr-only"
                      />
                      <config.icon className={cn("w-4 h-4", config.color)} />
                      <span className={cn(
                        "text-sm font-medium",
                        config.color
                      )}>
                        {config.label}
                      </span>
                    </label>
                  ))}
                </div>
              </td>
              <td className="px-6 py-4">
                <div className="flex items-center justify-end gap-2">
                  <button
                    onClick={() => handleEdit(item.id, item.status)}
                    className="p-2 text-retro-purple dark:text-white hover:text-retro-pink transition-colors rounded-lg hover:bg-retro-purple/5 dark:hover:bg-white/5"
                  >
                    <Edit className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => onDelete(item.id)}
                    className="p-2 text-red-600 hover:text-red-700 transition-colors rounded-lg hover:bg-red-50 dark:hover:bg-red-900/10"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}