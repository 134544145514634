import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { Navigate } from 'react-router-dom';
import { LoadingSpinner } from '../../../components/ui/LoadingSpinner';

export function DashboardLayout() {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <LoadingSpinner fullScreen />;
  }

  if (!user || user.role !== 'admin') {
    return <Navigate to="/auth" replace />;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-retro-purple/95">
      <main>
        <Outlet />
      </main>
    </div>
  );
}