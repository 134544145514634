import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import { ThemeProvider } from './context/ThemeContext';
import { ErrorBoundary } from './components/ErrorBoundary';
import { validateEnv } from './utils/env';
import './index.css';

validateEnv();

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ErrorBoundary>
      <HelmetProvider>
        <Router>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </Router>
      </HelmetProvider>
    </ErrorBoundary>
  </StrictMode>
);