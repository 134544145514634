import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { dashboardRoutes } from './routes/dashboardRoutes';
import MainNavigation from './components/layout/MainNavigation';
import MainFooter from './components/layout/MainFooter';
import { LoadingSpinner } from './components/ui/LoadingSpinner';
import { useScrollToTop } from './hooks/useScrollToTop';
import HomeHero from './components/home/HomeHero';

// Lazy load components avec chemins explicites
const NewsPageGrid = React.lazy(() => import('./components/news/NewsPageGrid'));
const NewsRead = React.lazy(() => import('./pages/news/NewsRead'));
const NewsCreate = React.lazy(() => import('./pages/news/NewsCreate'));
const NewsWritingFaq = React.lazy(() => import('./features/news/components/NewsWritingFaq'));
const SettingsPage = React.lazy(() => import('./pages/settings/SettingsPage'));
const EquipmentPageGrid = React.lazy(() => import('./components/equipment/EquipmentPageGrid'));
const EquipmentDetail = React.lazy(() => import('./pages/equipment/EquipmentDetail'));
const ShopPage = React.lazy(() => import('./components/shop/ShopPage'));
const SearchResultsPage = React.lazy(() => import('./pages/SearchResultsPage'));
const AuthPage = React.lazy(() => import('./pages/auth/AuthPage'));
const ProfilePage = React.lazy(() => import('./pages/auth/ProfilePage'));
const Error404Page = React.lazy(() => import('./pages/errors/Error404Page'));
const Error403Page = React.lazy(() => import('./pages/errors/Error403Page'));
const Error400Page = React.lazy(() => import('./pages/errors/Error400Page'));

function App() {
  useScrollToTop();

  return (
    <AuthProvider>
      <div className="min-h-screen bg-white dark:bg-retro-purple/95 flex flex-col">
        <MainNavigation />
        <div className="flex-grow">
          <Suspense fallback={<LoadingSpinner fullScreen />}>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={
                <>
                  <HomeHero />
                  <NewsPageGrid />
                  <EquipmentPageGrid />
                </>
              } />
              <Route path="/actualites" element={<NewsPageGrid />} />
              <Route path="/actualites/:id" element={<NewsRead />} />
              <Route path="/news-submit" element={<NewsCreate />} />
              <Route path="/news-writing-faq" element={<NewsWritingFaq />} />
              <Route path="/materiel" element={<EquipmentPageGrid />} />
              <Route path="/materiel/:slug" element={<EquipmentDetail />} />
              <Route path="/boutique" element={<ShopPage />} />
              <Route path="/search" element={<SearchResultsPage />} />

              {/* Auth Routes */}
              <Route path="/auth" element={<AuthPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/settings" element={<SettingsPage />} />

              {/* Dashboard Routes */}
              <Route path="/dashboard/*" element={dashboardRoutes.element}>
                {dashboardRoutes.children.map((route) => (
                  <Route 
                    key={route.path} 
                    path={route.path} 
                    element={route.element} 
                  />
                ))}
              </Route>

              {/* Error Routes */}
              <Route path="/400" element={<Error400Page />} />
              <Route path="/403" element={<Error403Page />} />
              <Route path="*" element={<Error404Page />} />
            </Routes>
          </Suspense>
        </div>
        <MainFooter />
      </div>
    </AuthProvider>
  );
}

export default App;