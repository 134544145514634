export const logger = {
  info: (message: string, data?: any) => {
    if (import.meta.env.DEV) {
      console.log(`[INFO] ${message}`, data || '');
    }
  },
  error: (message: string, error: any) => {
    if (import.meta.env.DEV) {
      console.error(`[ERROR] ${message}`, error);
      if (error?.message) console.error('Error message:', error.message);
      if (error?.details) console.error('Error details:', error.details);
    }
  },
  warn: (message: string, data?: any) => {
    if (import.meta.env.DEV) {
      console.warn(`[WARN] ${message}`, data || '');
    }
  }
};