import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { AlertModal } from '../../../components/ui/modals/AlertModal';
import { ImageUploader } from '../../../components/ui/ImageUploader';
import { CategorySelect } from './CategorySelect';
import { FormField } from './FormField';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import type { CreateNewsData, NewsStatus, News, UpdateNewsData } from '../types';

interface NewsFormProps {
  mode: 'create' | 'edit';
  initialData?: News;
  onSubmit: (data: CreateNewsData | UpdateNewsData, status: NewsStatus) => Promise<void>;
  isSubmitting: boolean;
  error?: string;
  isAdmin?: boolean;
}

export function NewsForm({ mode, initialData, onSubmit, isSubmitting, error: formError, isAdmin }: NewsFormProps) {
  const { user } = useAuth();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadError, setUploadError] = useState<string>();
  const [currentStatus, setCurrentStatus] = useState<NewsStatus>(initialData?.status || 'draft');
  const [formData, setFormData] = useState<Partial<CreateNewsData | UpdateNewsData>>({
    id: initialData?.id,
    title: initialData?.title || '',
    content: initialData?.content || '',
    category: initialData?.category || '',
    main_image: initialData?.main_image || '',
    additional_images: initialData?.additional_images || [],
    published_date: initialData?.published_date || new Date().toISOString(),
    original_article_url: initialData?.original_article_url || '',
    excerpt: initialData?.excerpt || '',
    status: currentStatus
  });

  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: [3, false] }],
        ['link'],
        ['clean']
      ]
    },
    formats: ['bold', 'italic', 'underline', 'list', 'header', 'link'],
    theme: 'snow',
    placeholder: 'Rédigez votre article ici... (maximum 10 000 caractères)'
  });

  useEffect(() => {
    if (quill && initialData?.content) {
      quill.clipboard.dangerouslyPasteHTML(initialData.content);
    }
  }, [quill, initialData]);

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        const content = quill.root.innerHTML;
        setFormData(prev => ({
          ...prev,
          content,
          status: currentStatus // Préserver le statut actuel
        }));
      });
    }
  }, [quill, currentStatus]);

  const handleImageError = (error: Error) => {
    setUploadError(error.message);
    setErrorMessage(error.message);
    setShowErrorModal(true);
  };

  const isValidTransition = (currentStatus: NewsStatus, newStatus: NewsStatus): boolean => {
    if (isAdmin) return true;
    
    if (currentStatus === 'draft' && newStatus === 'submitted') return true;
    if (currentStatus === 'submitted' && newStatus === 'draft') return true;
    if (currentStatus === newStatus) return true;
    
    return false;
  };

  const handleSubmit = async (e: React.FormEvent, newStatus: NewsStatus) => {
    e.preventDefault();
    setErrorMessage('');
    setUploadError(undefined);

    if (!user?.id) {
      setErrorMessage('Vous devez être connecté pour soumettre un article');
      setShowErrorModal(true);
      return;
    }

    if (!isValidTransition(currentStatus, newStatus)) {
      setErrorMessage('Cette transition de statut n\'est pas autorisée');
      setShowErrorModal(true);
      return;
    }

    try {
      const newsData = {
        ...formData,
        status: newStatus,
        author_id: mode === 'create' ? user.id : initialData?.author_id
      } as CreateNewsData | UpdateNewsData;

      await onSubmit(newsData, newStatus);
      setCurrentStatus(newStatus); // Mettre à jour le statut actuel après succès
    } catch (err) {
      if (err instanceof Error) {
        setErrorMessage(err.message);
        setShowErrorModal(true);
      }
    }
  };

  return (
    <form className="space-y-8">
      <AlertModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        title="Erreur"
        message={errorMessage}
      />

      <CategorySelect
        value={formData.category || ''}
        onChange={(value) => setFormData({ ...formData, category: value })}
      />

      <FormField
        id="title"
        label="Titre *"
        type="text"
        value={formData.title}
        onChange={(e) => setFormData({ ...formData, title: e.target.value })}
        placeholder="Titre de l'article (maximum 140 caractères)"
        required
      />

      <div className="space-y-2">
        <label className="block text-sm font-display uppercase text-retro-purple dark:text-white">
          Contenu *
        </label>
        <div ref={quillRef} className="text-base bg-white dark:bg-retro-purple/30 rounded-lg" />
      </div>

      <ImageUploader
        mainImage={formData.main_image || ''}
        additionalImages={formData.additional_images || []}
        onMainImageChange={(url) => setFormData({ ...formData, main_image: url })}
        onAdditionalImagesChange={(urls) => setFormData({ ...formData, additional_images: urls })}
        onError={handleImageError}
      />

      <FormField
        id="original_article_url"
        label="URL de l'article original"
        type="url"
        placeholder="https://..."
        value={formData.original_article_url}
        onChange={(e) => setFormData({ ...formData, original_article_url: e.target.value })}
      />

      <div className="flex gap-4 pt-8">
        {isAdmin ? (
          <>
            <button
              type="button"
              onClick={(e) => handleSubmit(e, 'published')}
              disabled={isSubmitting}
              className="flex-1 btn-primary"
            >
              {isSubmitting ? 'Publication...' : 'Publier l\'article'}
            </button>
            <button
              type="button"
              onClick={(e) => handleSubmit(e, 'draft')}
              disabled={isSubmitting}
              className="flex-1 btn-secondary"
            >
              Sauvegarder en brouillon
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              onClick={(e) => handleSubmit(e, 'submitted')}
              disabled={isSubmitting}
              className="flex-1 btn-primary"
            >
              {isSubmitting ? 'Soumission...' : 'Soumettre l\'article'}
            </button>
            <button
              type="button"
              onClick={(e) => handleSubmit(e, 'draft')}
              disabled={isSubmitting}
              className="flex-1 btn-secondary"
            >
              Enregistrer le brouillon
            </button>
          </>
        )}
      </div>
    </form>
  );
}