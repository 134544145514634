import React from 'react';
import { cn } from '../../utils/cn';

interface LoadingSpinnerProps {
  /**
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * @default 'retro-pink'
   */
  color?: string;
  /**
   * Classe CSS additionnelle
   */
  className?: string;
  /**
   * @default false
   */
  fullScreen?: boolean;
}

export function LoadingSpinner({ 
  size = 'medium', 
  color = 'retro-pink',
  className,
  fullScreen = false
}: LoadingSpinnerProps) {
  const sizeClasses = {
    small: 'h-6 w-6 border',
    medium: 'h-12 w-12 border-2',
    large: 'h-16 w-16 border-3'
  };

  const spinner = (
    <div 
      className={cn(
        'animate-spin rounded-full border-t border-b',
        sizeClasses[size],
        `border-${color}`,
        className
      )}
      role="status"
    >
      <span className="sr-only">Chargement...</span>
    </div>
  );

  if (fullScreen) {
    return (
      <div className="min-h-[73vh] flex items-center justify-center">
        {spinner}
      </div>
    );
  }

  return spinner;
}